import React from "react";
import Layout from "../components/Layout";
import { TermsOfServiceSection } from "../sections";
import { BlueCurve1 } from "../components/Curves";

const TermsOfServicePage = () => {
  return (
    <Layout title="ChatIQ | Terms Of Service" darkFooter>
      <TermsOfServiceSection />
      <BlueCurve1 />
    </Layout>
  );
};

export default TermsOfServicePage;
